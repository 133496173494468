import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService }       from '@omnilib/services/data.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-requestpassword',
  templateUrl: './requestpassword.component.html'
})
export class RequestPasswordComponent implements OnInit, OnDestroy {

  email:       string  = '';
  initialized: boolean = false;

  constructor ( public  data:       DataService
              , public  message:    MessageService
              , public  pageheader: PageHeaderService
              , public  router:     RouterService
              ) {
  }

  ngOnInit() {
    this.router.toggleshowwait( true );
    this.pageheader.setPageHeader( 'pagetitle.requestpassword', 'pagetext.requestpassword' );
    this.initialized     = true;
    this.router.toggleshowwait( false );
  }

  reset() {
    this.email    = '';
  }

  async submit() {
    this.router.toggleshowwait( true );
    if ( this.email != '') {
      let params = { "email" : this.email }
      let result = await this.data.callapi( 'post', 'auth/reset-password', params );
      this.message.setSuccessMessage ('requestpassword.success');

    }
    this.router.toggleshowwait( false );
  }

  enableReset() {
    return this.email != '';
  }

  enableSubmit() {
    return this.email != '';
  }

  ngOnDestroy() {
    return;
  }

}

import { Injectable }  from '@angular/core';
import { environment } from '@omnienvironments/omnipas/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  profile: string = 'student';

  getEnvValue( item:string ) {
    if ( this.profile != '' && typeof environment.config[this.profile] != 'undefined' && typeof environment.config[this.profile][item] != 'undefined' ) {
      return environment.config[this.profile][item];
    } else if ( typeof environment.config[item] != 'undefined' ) {
      return environment.config[item];
    } else {
      return null;
    }
  }
}
